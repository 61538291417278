<template>
    <div class="service-map">
        <tree-com :showDevice="isAdmin" @change="changeSelect" @click="nodeClick" ref="treeCompref"></tree-com>
        <x-handle @dragx="dragx"></x-handle>
        <div ref="container" class="map-container"></div>

        <right-aside ref="rAside"></right-aside>
        <!-- 设备状态 -->
        <device-info ref="device_info"></device-info>
    </div>
</template>

<script>
    import TreeCom from "./TreeCom";
    import XHandle from "./xhandle";
    import RightAside from "./rightAside";
    import DeviceInfo from "./DeviceInfo.vue";

    import LinkedPool from "./LinkedPool";
    import gcoord from "gcoord";
    import { getRotation } from "@/common/js/BMapUtil";

    import * as maptalks from "maptalks";
    import { ClusterLayer } from "maptalks.markercluster";

    import m0 from "@/assets/serviceMap/m0.png";
    import m1 from "@/assets/serviceMap/m1.png";
    import m2 from "@/assets/serviceMap/m2.png";
    import m3 from "@/assets/serviceMap/m3.png";
    import m4 from "@/assets/serviceMap/m4.png";
    import busOn from "@/assets/serviceMap/bus_online.svg";
    import busOff from "@/assets/serviceMap/bus_off.svg";

    const linkedPool = new LinkedPool();

    let serviceMap = null,
        clusterLayer = null;
    const clusterOptions = {
        noClusterWithOneMarker: true,
        maxClusterZoom: 16,
        //"count" is an internal variable: marker count in the cluster.
        symbol: {
            markerVerticalAlignment: "middle",
            markerHorizontalAlignment: "middle",
            markerFile: {
                property: "count",
                type: "interval",
                stops: [
                    [0, m0],
                    [9, m1],
                    [99, m2],
                    [200, m3],
                    [500, m4]
                ]
            },
            markerWidth: {
                property: "count",
                type: "interval",
                stops: [
                    [0, 53],
                    [9, 56],
                    [99, 66],
                    [200, 79],
                    [500, 90]
                ]
            },
            markerHeight: {
                property: "count",
                type: "interval",
                stops: [
                    [0, 53],
                    [9, 56],
                    [99, 66],
                    [200, 79],
                    [500, 90]
                ]
            }
        },
        drawClusterText: true,
        geometryEvents: true,
        single: true,
        zIndex: 9
    };
    export default {
        mounted() {
            this.$nextTick(() => {
                this.initMap();
                // 聚合
                this.initCluster();
            });

            linkedPool.on(data => {
                const msg = JSON.parse(data.content);
                this.updateMarker(msg.lng, msg.lat, msg.vhId);
                if (this.$refs.rAside) {
                    this.$refs.rAside.update(msg);
                }
            });
        },
        methods: {
            async initCluster() {
                let restRecord = 0,
                    vlist = [],
                    pageIndex = 1;
                do {
                    const result = await this.$http.post("/realtime/getRTVehicleInfo", {
                        pageIndex,
                        pageSize: 200
                    });
                    const { total, list } = result.detail;
                    vlist = vlist.concat(list);
                    restRecord = total - vlist.length;
                    pageIndex++;
                } while (restRecord > 0);

                const markers = this.transformPoi2Marker(vlist);
                clusterLayer = new ClusterLayer("cluster", markers, clusterOptions);
                serviceMap && serviceMap.addLayer(clusterLayer);

                const fm = markers[0];
                serviceMap.setCenter(fm.getCoordinates());
            },
            transformPoi2Marker(list) {
                let markers = [];
                if (list && list.length) {
                    list.forEach(v => {
                        const { vhNo: plateNum, vhId: vehicleId, devicePosList, online } = v;
                        const { lng, lat } = devicePosList[0];
                        if (lng && lat) {
                            const point = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
                            const marker = new maptalks.Marker(point, {
                                id: vehicleId,
                                properties: v,
                                cursor: "pointer",
                                symbol: [
                                    {
                                        markerFile: this.getImage(online), //标注点图标
                                        markerWidth: 100,
                                        markerHeight: 50
                                    },
                                    {
                                        textName: plateNum,
                                        textSize: 12,
                                        textHaloRadius: 1
                                    }
                                ]
                            }).on("click", this.clickMarker);
                            markers.push(marker);
                        }
                    });
                }
                return markers;
            },
            // 更新角度和位置
            updateMarker(lng, lat, vehicleId) {
                const marker = clusterLayer.getGeometryById(vehicleId);
                const curPos = marker.getCoordinates();
                const tarPos = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
                marker.setCoordinates(tarPos);
                const rotate = getRotation(serviceMap, curPos, { x: tarPos[0], y: tarPos[1] });
                let symbol = marker.getSymbol();
                marker.setSymbol([
                    {
                        ...symbol[0],
                        markerRotation: rotate
                    },
                    symbol[1]
                ]);
            },
            getImage(online) {
                if (online === 1) {
                    return busOn;
                } else {
                    return busOff;
                }
            },
            clickMarker(event) {
                const properties = event.target.properties;
                const [{ clientId, lng, lat, time }] = properties.devicePosList;
                const p = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
                this.$refs.rAside.init({
                    online: properties.online,
                    plateNum: properties.vhNo,
                    vehicleId: properties.vhId,
                    clientId,
                    lng: p[0],
                    lat: p[1],
                    time
                });
            },
            changeView() {},
            nodeClick(data) {
                if (data.type === 2) {
                    this.$refs.device_info.init({
                        deviceId: data.id,
                        plateNum: data.pData.text,
                        orgName: this.getOrgName(data)
                    });
                } else if (data.type === 1) {
                    const marker = clusterLayer.getGeometryById(data.id);
                    serviceMap.setCenter(marker.getCoordinates());
                    serviceMap.setZoom(16);
                }
            },
            getOrgName(data) {
                let parent = data,
                    orgNames = [];
                while (parent) {
                    if (parent.type === 0) {
                        orgNames.push(parent.text);
                    }
                    parent = parent.pData;
                }
                orgNames.reverse();
                return orgNames.join("");
            },
            changeSelect(data, checked) {
                if (data.type == 1) {
                    this.changeVehicle(data, checked);
                } else if (data.type == 0) {
                    // this.changeLine(data, checked);
                    // this.$refs.route.init(data, checked);
                }
            },
            changeLine() {},
            changeVehicle(data, checked) {
                if (checked) {
                    this.$http
                        .post(
                            "/base/device/queryByVehicleId",
                            { vehicleId: data.id },
                            {
                                type: "format",
                                isRequestParam: false
                            }
                        )
                        .then(res => {
                            let clientIds = res.detail.map(v => v.clientId);
                            this.$http.post("/base/device/getDeviceSubscribeAddr", clientIds).then(dRes => {
                                dRes.detail.forEach(d => {
                                    linkedPool.add(d);
                                });
                            });
                        });
                } else {
                    this.$http
                        .post(
                            "/base/device/queryByVehicleId",
                            { vehicleId: data.id },
                            {
                                type: "format",
                                isRequestParam: false
                            }
                        )
                        .then(res => {
                            const list = res.detail.map(v => v.clientId);
                            list.forEach(l => {
                                linkedPool.delete(l);
                            });
                        });
                }
            },
            dragx(x) {
                this.$refs.treeCompref.setWidth(x);
            },
            initMap() {
                const dom = this.$refs.container;
                serviceMap = new maptalks.Map(dom, {
                    center: [116.404, 39.915],
                    zoom: 13,
                    minZoom: 6,
                    // maxZoom: 16,
                    spatialReference: {
                        projection: "baidu"
                    },
                    attribution: false,
                    baseLayer: new maptalks.TileLayer("map", {
                        urlTemplate: `//maponline{s}.bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=1&udt=${this.getCurrentDate}`,
                        subdomains: [0, 1, 2, 3]
                    })
                });
                // 限制地图区域
                const extent = new maptalks.Extent(70, 0, 130, 55);
                serviceMap.setMaxExtent(extent);
            }
        },
        components: {
            TreeCom,
            XHandle,
            RightAside,
            DeviceInfo
        },
        computed: {
            isAdmin() {
                return !!this.$store.state.info.isAdmin;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .service-map {
        height: 100%;
        display: flex;
        position: relative;
        overflow: hidden;
        .map-container {
            flex: 1;
        }
    }
</style>
