<template>
    <div>
        <div class="track-map" ref="trackContainer"></div>

        <div class="track-control">
            <jy-query>
                <jy-query-item label="回放时间" prop="vehicleNo" :span="3.2">
                    <el-date-picker
                        v-model="form.date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :clearable="false"
                    ></el-date-picker>
                </jy-query-item>
                <template #rightCol>
                    <el-button type="primary" size="small" @click="query">查询</el-button>
                </template>
            </jy-query>
            <div style="text-align: right;">
                <el-button :disabled="isRunning || gpsData.length === 0" type="primary" size="small" @click="start">回放</el-button>
                <el-button :disabled="!isRunning" type="primary" size="small" @click="pause">暂停</el-button>
                <el-button :disabled="!isRunning" type="primary" size="small" @click="stop">结束</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import gcoord from "gcoord";
    import startImage from "@/assets/startIcon.png";
    import endImage from "@/assets/endIcon.png";

    export default {
        data() {
            return {
                routeId: "",
                clientId: "",

                isRunning: false,

                lushu: null,
                gpsData: [],
                trackMap: null,

                form: {
                    date: [
                        new Date().Format("yyyy-MM-dd") + " 00:00:00",
                        new Date().Format("yyyy-MM-dd") + " 23:59:59"
                    ]
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                this.initMap();
            });
        },
        destoryed() {
            if (this.isRunning) {
                this.stop();
            }
            this.trackMap.clearOverlays();
            this.trackMap = null;
            this.lushu = null;
            this.gpsData = [];
        },
        methods: {
            init(list, options) {
                this.routeId = options.orgId;
                this.clientId = options.clientId;
                this.getTrack();
            },
            initMap() {
                const dom = this.$refs.trackContainer;
                this.trackMap = new BMap.Map(dom);
                this.trackMap.enableScrollWheelZoom();
                this.trackMap.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
            },
            start() {
                this.lushu.start();
                this.isRunning = true;
            },
            pause() {
                this.lushu.pause();
                this.isRunning = false;
            },
            stop() {
                this.lushu.stop();
                this.isRunning = false;
            },
            getTrack() {
                let url = "/base/vehicle/getGpsDataByDevice";
                this.trackMap.clearOverlays();
                this.$http
                    .post(url, {
                        deviceNo: this.clientId,
                        routeId: this.routeId,
                        beginTime: this.form.date[0],
                        endTime: this.form.date[1]
                    })
                    .then(res => {
                        console.log(res, 'res');
                        for (let detail of res.detail) {
                            [detail.lng, detail.lat] = gcoord.transform([detail.lng, detail.lat], gcoord.WGS84, gcoord.BD09);
                            detail.time = ("20" + detail.time.toString().slice(0, 12))
                                .replace(
                                    new RegExp(`(\\d{4})${'(\\d{2})'.repeat(5)}`),
                                    '$1-$2-$3 $4:$5:$6'
                                );
                        }
                        this.gpsData = res.detail;
                        const arrPois = res.detail.map(v => {
                            const { lng, lat } = v
                            return new BMap.Point(lng, lat);
                        });
                        this.getPolyline(arrPois);
                        this.trackMap.setViewport(arrPois);

                        //   添加标注
                        if (arrPois.length) {
                            let startPoint = new window.BMap.Point(arrPois[0].lng, arrPois[0].lat);
                            let endPoint = new window.BMap.Point(arrPois[arrPois.length - 1].lng, arrPois[arrPois.length - 1].lat);
                            var startIcon = new BMap.Icon(startImage, new BMap.Size(50, 50));
                            var endIcon = new BMap.Icon(endImage, new BMap.Size(50, 50));
                            let marker = new BMap.Marker(startPoint, {
                                icon: startIcon
                            });
                            let endmarker = new BMap.Marker(endPoint, {
                                icon: endIcon
                            });
                            this.trackMap.addOverlay(marker);
                            this.trackMap.addOverlay(endmarker);

                            this.trackMap.setCenter(startPoint);
                            this.trackMap.setZoom(14);
                        }

                        this.getLushu();
                    });
            },
            // 创建轨迹
            getPolyline(trackList) {
                let tempList = [trackList[0]];
                for (let index = 1; index < trackList.length; index++) {
                    const f = trackList[index - 1];
                    const l = trackList[index];
                    if (BMapLib.GeoUtils.getDistance(f, l) <= 300) {
                        tempList.push(l);
                    } else {
                        // 绘制
                        const polyline = new BMap.Polyline(tempList, {
                            strokeColor: "blue", //设置颜色
                            strokeWeight: 5, //宽度
                            strokeOpacity: 0.5 //透明度
                        });
                        this.trackMap.addOverlay(polyline);
                        tempList = [l];
                    }
                }
                if (tempList.length > 1) {
                    const polyline = new BMap.Polyline(tempList, {
                        strokeColor: "blue", //设置颜色
                        strokeWeight: 5, //宽度
                        strokeOpacity: 0.5 //透明度
                    });
                    this.trackMap.addOverlay(polyline);
                }
            },
            // 创建路书
            getLushu() {
                this.lushu = new BMapLib.LuShu(this.trackMap, this.gpsData, {
                    defaultContent: "", //"从天安门到百度大厦"
                    autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                    icon: new BMap.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                    speed: 200, //运行速度
                    enableRotation: true, //是否设置marker随着道路的走向进行旋转
                    landmarkPois: []
                });
            },
            query() {
                if (this.isRunning) {
                    this.stop();
                }
                this.getTrack();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .track-map {
        height: 480px;
    }
    .track-control {
        margin-top: 10px;
        text-align: center;
    }
</style>
