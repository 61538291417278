//路单审核状态
export function getAuditStatus() {
    let list = [{
            value: "0",
            label: "待审核"
        },
        {
            value: "2",
            label: "审核通过"
        },
        {
            value: "3",
            label: "审核不通过"
        },
        {
            value: "1",
            label: "系统自动审核"
        }
    ];
    return list;
}

//运行情况
export function getRunStatus() {
    let list = [{
            value: "0",
            label: "未发车"
        },
        {
            value: "1",
            label: "已发车"
        },
        {
            value: "2",
            label: "已完成"
        }
    ];
    return list;
}
//方案类型
export function getSchemeTs() {
    let list = [{
            value: "1",
            label: "上行"
        },
        {
            value: "2",
            label: "下行"
        },
        {
            value: "3",
            label: "环形"
        }
    ];
    return list;
}

export function deviceType() {
    let list = [{
            value: 0,
            label: "主动安全"
        },
        {
            value: 1,
            label: "GB32960"
        },
        {
            value: 2,
            label: "DVR"
        },
        {
            value: 3,
            label: "客流"
        },
        {
            value: 4,
            label: "调度"
        }
    ];
    return list;
}

export function tradeWay() {
    let list = [{
            value: '0',
            label: '企业对公转账'
        },
        {
            value: '1',
            label: '现金'
        },
        {
            value: '2',
            label: '微信支付'
        },
        {
            value: '3',
            label: '账户余额支付'
        }
    ]
    return list
}

export default {};