<template>
    <div>
        <el-divider></el-divider>
        <div class="video_frames">
            <video-player :stream="false" :channel="false" :autoPlay="false" ref="vplayer" class="vplayer" :params="getParams" :vtitle="getTitle"></video-player>
        </div>
        <div class="video_control">
            <div class="video_configure">
                <span>选择设备</span>
                <el-select v-model="deviceId" size="small" @change="changeDeviceId">
                    <el-option v-for="device in deviceList" :key="device.clientId" :label="getDeviceName(device.dType)" :value="device.deviceId"></el-option>
                </el-select>
                <el-select v-model="channelId" size="small" @change="changeChannel">
                    <el-option v-for="channel in 16" :key="channel" :label="`通道${channel}`" :value="channel"></el-option>
                </el-select>
                <el-select v-model="stream" size="small">
                    <el-option v-for="stream in streamList" :key="stream.value" :label="stream.label" :value="stream.value"></el-option>
                </el-select>
            </div>
            <!-- <el-button size="small" type="primary" @click="mutiPlay" v-collect="getPageInfo({label: 'video'})">多路视频</el-button> -->
        </div>
        <el-divider></el-divider>
    </div>
</template>

<script>
    import VideoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
    import { deviceType } from "@/common/js/wordbook.js";
    export default {
        methods: {
            init(deviceList, vehicleNo) {
                // 查询车辆设备
                this.deviceList = deviceList;

                this.vehicleNo = vehicleNo;

                this.setTypeList(deviceType());
                if (deviceList && deviceList.length) {
                    this.deviceId = this.deviceList[0].deviceId;
                    this.$nextTick(() => {
                        // 第一次不需要断开重连
                        this.$refs.vplayer.changeDeviceId(this.deviceId);
                    });
                }
            },
            setTypeList(typeList) {
                this.typeList = typeList;
            },
            changeDeviceId() {
                this.$refs.vplayer && this.$refs.vplayer.changeDeviceId(this.deviceId);
            },
            changeChannel() {
                this.$refs.vplayer && this.$refs.vplayer.changeChannelId(this.channelId);
            },
            changeTalk(uuid, openFunc) {
                if (!this.isTalking) {
                    openFunc();
                }
            },
            getDeviceName(val) {
                const type = this.typeList.find(v => v.value == val);
                return type ? type.label : "未知";
            }
        },
        destroyed() {
            this.channelId = 1;
            this.stream = 0;
            this.deviceId = "";
        },
        beforeDestroy() {
            this.$refs.vplayer && this.$refs.vplayer.destroy();
        },
        data() {
            return {
                vehicleNo: "",
                deviceList: [],
                deviceId: "",
                channelId: 1,
                stream: 0,
                streamList: [
                    {
                        label: "主码流",
                        value: 0
                    },
                    {
                        label: "子码流",
                        value: 1
                    }
                ],

                typeList: []
            };
        },
        computed: {
            getParams() {
                return {
                    clientId: this.deviceId,
                    channelId: this.channelId
                };
            },
            getTitle() {
                return this.vehicleNo + "-" + this.deviceId + "-" + this.channelId;
            }
        },
        components: {
            VideoPlayer
        }
    };
</script>
<style lang="scss" scoped>
    .video_frames {
        width: 440px;
        height: 320px;
        border: 1px solid #ccc;
    }
    .video_control {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .video_configure {
            span {
                font-size: 14px;
                color: gray;
            }
            .el-select {
                width: 90px;
                margin: 0 5px;
                & ::v-deep .el-input__inner {
                    padding: 0 15px 0 5px;
                    font-size: 12px;
                }
            }
        }
    }
</style>
